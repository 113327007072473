
<div  class="container-fluid g-0">

  <!-- HEADER -->
  <!-- ONLY DISPLAY HEADER IN OPERATION PAGES -->
  <app-header style="top: 0; background-color: transparent!important; display: none;"></app-header>

  <!-- MAIN -->
  <!-- MAIN CONTENT FOR ACESS PAGE -->
  <router-outlet *ngIf="IsLoginPageActive()" style="flex-grow: 1;"></router-outlet>

  <!-- MAIN CONTENT FOR OPERATION PAGE-->
  <main  *ngIf="!IsLoginPageActive()">



      <!-- DISPLAY CONTENT OF OPERATION PAGES -->
      <!-- DESKTOP -->
      <div class="display-area p-0" [ngClass]="{'display-area-shrink': sideNavStatus}">
          <router-outlet   style="flex-grow: 1;"></router-outlet>
      </div>

  </main>

  <!-- FOOTER - DISPLAYED IN EVERY PAGE  -->
  <app-footer></app-footer>

</div>
