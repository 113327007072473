import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {

  constructor() { }

  /**
   * Redirects to a URL with query parameters.
   * @param url The URL to redirect to.
   * @param params The query parameters as key-value pairs.
   */
  redirectToUrlWithParams(url: string, params: { [key: string]: any }) {
    // Construct the query string from parameters
    let paramsString = '';
    Object.keys(params).forEach((key, index) => {
      if (index === 0) {
        paramsString += `?${key}=${params[key]}`;
      } else {
        paramsString += `&${key}=${params[key]}`;
      }
    });

    // Redirect to the URL with the query string
    window.location.href = url + paramsString;
  }
  
}
