import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

interface JwtPayload {
  userid: string;
  email: string;
  firstname: string;
  lastname: string;
  eid: string;
  preferences: { langid: string };
  certified: null | any; // Adicionando a propriedade certified
  accesstoken: { exp: number; iat: number }; // Adicionando a propriedade accesstoken
  refresh_token: { exp: number; iat: number }; // Adicionando a propriedade refresh_token
  roles: { roles: string }[]; // Adicionando a propriedade roles
  // Outras propriedades, se houver
}

@Injectable({
  providedIn: 'root'
})
export class JwtDecodeService {

  constructor() { }

  // Function to decode a JWT token
  decodeToken(token: string) {

    // Decode the JWT token
    const decodedToken = jwtDecode(token);


    // Return the payload information
    return decodedToken as JwtPayload;


  }

}
