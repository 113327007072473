import { Component, OnInit } from '@angular/core';
import { SamlService } from '../service/saml.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrl: './sign-out.component.css'
})

export class SignOutComponent implements OnInit {

  public delayTImer: number = 3000;

  constructor(

    public SAML: SamlService,
    public authService: MsalService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    // alert(" signout component constructor");

    this.SAML.microsoft.initialize().subscribe((response) => {

      this.authService.ssoSilent({})
        .subscribe(
          (response: AuthenticationResult) => {
            // Usuário autenticado, continuar com a lógica do componenteal
            // alert("USUARIO AUTENTICADO NO CONSTRUTOR")
            this.SAML.microsoft.logout().subscribe(() => {
              // alert('Logout realizado com sucesso!')
              // this.router.navigate(['/login']);

              // Você pode adicionar qualquer lógica adicional após o logout aqui
            }, error => {
              console.error('Error when logging out:', error);
              // alert('Erro ao fazer logout!')
              // Lidar com erros de logout aqui, se necessário
            });


            // this.joinNovaByMicrosoft(response.account.username);
          },
          (error) => {
            // Usuário não autenticado, redirecionar para a página de login
            // this.authService.loginRedirect();
          }
        );
    })

  }

  async ngOnInit(): Promise<void> {

    localStorage.clear();
    sessionStorage.clear();
    // this.SAML.endLogin();

    const cookies = document.cookie.split('; ');

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
    }

    await this.delay();

    // this.route.url.subscribe((url: any) => {
    //   if(url[1]){
    //     if(url[1].path === 'account-blocked'){
    //       this.router.navigate(['/login/account-blocked']);
    //     }
    //   }
    // });

    const error = this.route.snapshot.paramMap.get('error');

    switch(error){
      case 'account-blocked':
        this.router.navigate(['/login/account-blocked']);
        break;
      case 'user-blocked':
        this.router.navigate(['/login/user-blocked']);
        break;
      case 'user-not-exist':
        this.router.navigate(['/login/user-not-exist']);
        break;
      case 'account-not-exist':
        this.router.navigate(['/login/account-not-exist']);
        break;
      default:
        this.router.navigate(['/login']);
        break;
    }
  }

   // Asynchronous method to introduce a delay
  async delay(): Promise<void> {
    // Returning a Promise that resolves after a certain delay
    return new Promise(resolve => {
        // Using setTimeout to introduce the delay
        setTimeout(() => {
            // Resolving the Promise after the delay
            resolve();
        }, this.delayTImer); // Delay time specified by this.delayTimer
    });
  }

  /*

  acoes inerentes ao signout:



  this.SAML.microsoft.logout().subscribe(() => {
              //console.log('Logout realizado com sucesso!');
              // Você pode adicionar qualquer lógica adicional após o logout aqui
            }, error => {
              console.error('Erro ao fazer logout:', error);
              // Lidar com erros de logout aqui, se necessário
            });

  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
  }

  */

}
