import { Component, OnInit, ElementRef, ViewChild, Renderer2, AfterViewChecked, DoCheck} from '@angular/core';
import { Ribbon } from '../constants/ribbon';
import { SystemMessageService } from '../service/system-message.service';








@Component({
  selector: 'app-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.css']
})
export class SystemMessageComponent implements OnInit, DoCheck {


  constructor(public systemMessageService: SystemMessageService, private elementRef:ElementRef, private renderer:Renderer2) {

  }


  ngOnInit() {
     document.addEventListener('click', this.handleDocumentClick.bind(this));//Watches for click event on the document
  }
  ngDoCheck(){
    //for success ribbons deactivate prevent click + ribbon dissapear after 5s
    if(this.systemMessageService.showRibbon //if ribbon exits
        && this.systemMessageService.selectedRibbon?.value === 'success' // && ribbon type is success
          && document.getElementsByClassName('preventClick')[0] ){ //&& element with prevenClick class exists
      this.renderer.removeClass(document.getElementsByClassName('preventClick')[0],"preventClick"); // removes the click blocker class
      setTimeout(()=>{
        this.systemMessageService.showRibbon = false //hides ribbon after 5 seconds
      },5000)
    }
  }

  @ViewChild('box') boxElement!: ElementRef | undefined;

  // please verify errors when code is uncommented
  //handles document mouse click event
  handleDocumentClick(event: MouseEvent) {
    //checks status of ribbon message
    if(this.systemMessageService.showRibbon ){//if ribbon appears on screen
      //checks mouse click position
       if (this.boxElement && !this.boxElement.nativeElement.contains(event.target)) {//if box element exists and click target outisde of it
        if(!(this.systemMessageService.selectedRibbon?.value === 'success'))
       this.systemMessageService.shake(this.boxElement); //shakes element when clicked outside of it
      }
     }
  }
}



