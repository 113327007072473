import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';

export interface ServerStatus {
  serverLocation: string;
  serverStatus: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  private maintenanceKey = 'maintenance_data';

  public serverStatusArray: ServerStatus[] = [];

    
    constructor(private localStorageService: LocalStorageService,) { }

  // Simulation using local storage while we do not have an api for this purpose
  getMaintenanceData(): any {
    const maintenanceDataString = localStorage.getItem(this.maintenanceKey);
    if (maintenanceDataString !== null) {
      return JSON.parse(maintenanceDataString);
    } else {
      
      return null; 
    }
  }

  // will be deleted when use of the api is started
  setMaintenanceData(data: any): void {
    localStorage.setItem(this.maintenanceKey, JSON.stringify(data));
  }

  // Limpa os dados de manutenção do Local Storage
  clearMaintenanceData(): void {
    localStorage.removeItem(this.maintenanceKey);
  }
  
}
