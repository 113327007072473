import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '../service/theme.service';


@Component({
  selector: 'app-loading-element',
  templateUrl: './loading-element.component.html',
  styleUrl: './loading-element.component.css',
  template: `
    <div class="fa-3x" [@fadeInOut]="fadeInOutClass"> <!-- Animate based on the fadeInOutClass -->
      <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl" style="color: #328d1e82"></i> <!-- Font Awesome spinner icon -->
    </div>
  `
})

export class LoadingElementComponent implements OnInit {

  // Initial animation class
  fadeInOutClass = 'fade-in';

  currentIndex = 0;

  progress: number = 0;
  direction: number = 1; // 1 for forward, -1 for backward
  colors: string[] = ['#FF5733', '#FFC300', '#85C1E9', '#52BE80', '#F1948A']; // Array of colors

  @ViewChild('myBar') myBar!: ElementRef;

  progressLabel: string = '0%';
  barWidth: number = 0;
  barHeight: number = 24;

  // Constructor with injected ThemeService
  constructor(public theme: ThemeService,) {}
  ngOnInit() {
    this.animateProgress();
  }

  animateProgress(): void {
    setInterval(() => {
      this.progress += this.direction;
      if (this.progress >= 100 || this.progress <= 0) {
        this.direction *= -1; // Change direction when reaching the end
        this.changeColor();
      }
    }, 50);
  }

  changeColor(): void {
    if (this.colors.length > 0) {
      const currentColor = this.colors.shift(); // Remove the first color and get it
      if (currentColor !== undefined) {
        this.colors.push(currentColor); // Add it to the end of the array
      }
    }
  }
  
  

  getNextColor() {
    const color = this.colors[this.currentIndex];
    this.currentIndex = (this.currentIndex + 1) % this.colors.length;
    return color;
  }

  move(): void {
    let width = 20;
    const id = setInterval(() => {
      if (width >= 100) {
        clearInterval(id);
      } else {
        width++;
        this.barWidth = width;
        this.progressLabel = width + '%';
      }
    }, 50);
  }

}
