import { NavigationService } from '../service/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  HostListener,
  Renderer2,
  ElementRef,
  forwardRef,
  Inject,
} from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  public userName: any = ''; // Variable for storing the user's name
  unreadNotifications = 2; // Variable for storing the number of unread notifications
  isSwitchOn: boolean = false; // Flag for switch status
  isDarkMode: boolean = false; // Flag for dark mode
  menuStatus: boolean = false; // Flag for menu status
  showHeader: boolean = false; // Flag for showing the header
  public getScreenWidth: any; // Variable for storing the screen width
  public screenWidthDeadLine: any = 768; // Threshold for small screen width
  public getScreenHeight: any; // Variable for storing the screen height
  smallscreen: boolean = false; // Flag for small screen
  userid: string = 'a'; // Variable for storing the user id
  currentUser: any = [];
  public notifications: boolean = false; //Notifications dialog box

  // Variable to store the language selected value
  public selectedLanguage: string = '';

  // Retrieve current language selected from local storage
  languageStatus: string = this.localStorageService.getItem('language');
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private elRef: ElementRef, // Inject ElementRef here without @Inject

    @Inject(forwardRef(() => TranslateService))
    @Inject(forwardRef(() => TranslateService))
    private translate: TranslateService,
    public theme: ThemeService,
    public nav: NavigationService
  ) {}

  // Lifecycle hook called when the component is initialized
  ngOnInit() {
    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null) {
      // Set the default language to French
      this.translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      this.translate.use(this.languageStatus);
    }
    // Check if the current page is the login page and set the theme to light if it is
    this.IsLoginPageActive();

    // Determine whether to show the header based on the current route
    this.showHeader = !this.router.url.includes('/login');

    // Get the current screen width and height
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    // Initialize small screen state
    this.smallscreen = false;

    // Check and adjust the screen width state
    this.checkScreenWidth();

    this.theme.getThemeClass();
  }

  // Method for adjusting the toggle status
  adjustToggleStatus() {
    // Get a reference to the slider element
    const slider = document.querySelector('.slider');

    // Retrieve the saved theme status from local storage
    this.theme.themeStatus = this.localStorageService.getItem('theme');

    // Check if the theme status is set to 'dark'
    if (this.theme.themeStatus == 'dark') {
      // Add the 'checked' class to the slider to indicate it's on (dark theme)
      this.renderer.addClass(slider, 'checked');
    } else {
      // Remove the 'checked' class from the slider to indicate it's off (light theme)
      this.renderer.removeClass(slider, 'checked');
    }
  }

  // Method for toggling the dark mode
  toggleDarkMode(event: Event) {
    // Toggle the switch status
    this.theme.isSwitchOn = !this.theme.isSwitchOn;

    // Get the target element of the event as an input element
    const target = event.target as HTMLInputElement;

    // Update the dark mode status based on the switch's checked state
    this.theme.isDarkMode = target.checked;

    // Apply dark mode styling to the body and update theme status in local storage
    if (this.theme.isDarkMode) {
      // Add the 'dark-mode' class to the body to enable dark mode
      document.body.classList.add('dark-mode');

      // Update theme status and save it in local storage
      this.theme.themeStatus = 'dark';
      this.localStorageService.addItem('theme', this.theme.themeStatus);
    } else {
      // Remove the 'dark-mode' class from the body to disable dark mode
      document.body.classList.remove('dark-mode');

      // Update theme status and save it in local storage
      this.theme.themeStatus = 'light';
      this.localStorageService.addItem('theme', this.theme.themeStatus);
    }
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event']) onWindowResize() {
    // Update the screen width and height based on the resized window
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    // Check and adjust the screen width state
    this.checkScreenWidth();
  }

  // Method for checking if the login page is active
  IsLoginPageActive() {
    // Get the current URL of the page
    const url = window.location.href;

    // Set the switch status to off
    this.theme.isSwitchOn = false;

    // Return true if the URL includes 'login', otherwise return false
    return url.includes('login');
  }

  // Method for checking the screen width
  checkScreenWidth() {
    // Compare the current screen width with the defined threshold
    if (this.getScreenWidth <= this.screenWidthDeadLine) {
      // If the screen width is below or equal to the threshold, set smallscreen state to true
      this.smallscreen = true;
    } else {
      // If the screen width is above the threshold, set smallscreen state to false
      this.smallscreen = false;
    }
  }
}
